import Vue from 'vue'

const components = {
  'FeatherIcon':   () => import('@core/components/feather-icon/FeatherIcon.vue'),
  'ListAddInput': () => import('@core/components/dashboard/ListAddInput.vue'),
  'BaseRadioButtonGroup': () => import('@core/components/dashboard/BaseRadioButtonGroup.vue'),
  'ExportList': () => import('@/views/components/export-list/ExportList.vue'),
}

Object.entries(components).forEach(([name, component]) => Vue.component(name, component))
